import { IconInformation, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { Tooltip } from '@klarna/mp-ui';
import { AddOriginModal } from '@merchant-portal/credentials';
import { hooks as i18nHooks } from 'mage-i18n';
import React from 'react';
import styled from 'styled-components';
import { useClientId } from '../../hooks/useClientId';
import { useMid } from '../../hooks/useMid';
import GenerateButton from './GenerateButton';
import ManageClientId from './ManageClientId';
const Container = styled.div.withConfig({ displayName: "Container", componentId: "sc-vgtedb" }) `
  border-radius: 16px;
  border: 1px solid #E5E5E5;
  background-color: rgb(255, 255, 255);
  padding: 24px;
  text-align: left;
  position: relative;
`;
const TooltipContainer = styled.div.withConfig({ displayName: "TooltipContainer", componentId: "sc-2xp9rk" }) `
  position: absolute;
  top: 24px;
  right: 24px;
`;
const TRANSLATION_PREFIX = 'home-fe.boosters-home-v03.client-identifier-section';
export default function ClientIdSection() {
    const { selectedMid } = useMid();
    const { isAddOriginModalVisible, onAddOriginSuccess, setIsAddOriginModalVisible, isGenerateButtonVisible } = useClientId();
    const t = i18nHooks.useTranslator();
    return (React.createElement(Container, null,
        React.createElement(TooltipContainer, null,
            React.createElement(Tooltip, { text: t(`${TRANSLATION_PREFIX}.tooltip`), placement: 'bottom-end' },
                React.createElement(IconInformation, null))),
        React.createElement(Typography, { textToken: 'text-style/headings/titles/bold/grande' }, t(`${TRANSLATION_PREFIX}.title`)),
        React.createElement(SpacerVertical, { spaceToken: 'space/100' }),
        React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular' }, t(`${TRANSLATION_PREFIX}.description`)),
        React.createElement(SpacerVertical, { spaceToken: 'space/300' }),
        isGenerateButtonVisible ? React.createElement(GenerateButton, null) : React.createElement(ManageClientId, null),
        selectedMid &&
            React.createElement(AddOriginModal, { mid: selectedMid, onSuccess: onAddOriginSuccess, isVisible: isAddOriginModalVisible, setIsVisible: setIsAddOriginModalVisible })));
}

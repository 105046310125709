import { __rest } from "tslib";
import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import useLabelTranslation from '../hooks/useLabelTranslation';
ReadOnlyField.propTypes = {
    name: PropTypes.string.isRequired
};
export default function ReadOnlyField(_a) {
    var { name } = _a, props = __rest(_a, ["name"]);
    const label = useLabelTranslation(name);
    const [field] = useField(name);
    return (React.createElement("div", Object.assign({ "data-testid": `settings.readonlyfield.${name}` }, props),
        React.createElement(Typography, { textToken: "text-style/text/labels/default/regular", colorToken: "colors/text/neutral" }, label),
        React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/medium' }, field.value),
        React.createElement(SpacerVertical, { spaceToken: 'space/100' })));
}

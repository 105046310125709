import { ActionIconNewTab, ButtonPrimary, DesignTokens, SpacerVertical, Typography } from '@klarna/bubble-ui';
import Insights from 'mage-insights';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import CardWrapper from '../../../components/AppCard/CardWrapper';
import { BREAKPOINT_PX } from '../../../constants';
export default function ExternalAppCard({ buttonText, clientId, description, illustration, redirectUrl, title }) {
    const designContext = DesignTokens.useDesignContext();
    const category = `marketing-home/external-app-card/${clientId}`;
    useEffect(() => {
        Insights.event({
            category,
            action: 'impression'
        });
    }, []);
    const onClick = () => {
        Insights.event({
            category,
            action: 'click'
        });
        window.open(redirectUrl, '_blank');
    };
    const CardContainer = styled.div.withConfig({ displayName: "CardContainer", componentId: "sc-skl4r9" }) `
    display: flex;
    flex-direction: row;
    border: 1px solid ${designContext.get('colors/borders/subtle-floating').toString()};
    border-radius: 16px;
    width: 100%;
    padding: 32px 24px;

    @media (max-width: ${BREAKPOINT_PX.TABLET}px) {
      flex-direction: column;
    }
  `;
    const IconContainer = styled.div.withConfig({ displayName: "IconContainer", componentId: "sc-ym0am8" }) `
    display: flex;
    align-items: center;
  `;
    const ContentContainer = styled.div.withConfig({ displayName: "ContentContainer", componentId: "sc-1asssqo" }) `
    display: flex;
    flex-direction: column;
    padding: 0 24px;

    @media (max-width: ${BREAKPOINT_PX.TABLET}px) {
      padding: 24px 0;
    }
  `;
    const ActionButtonContainer = styled.div.withConfig({ displayName: "ActionButtonContainer", componentId: "sc-10uf321" }) `
    display: flex;
    align-items: center;
    margin-left: auto;

    @media (max-width: ${BREAKPOINT_PX.TABLET}px) {
      margin-left: 0;
    }
  `;
    return (React.createElement(React.Fragment, null,
        React.createElement(CardWrapper, { onClick: onClick },
            React.createElement(CardContainer, null,
                React.createElement(IconContainer, null, illustration),
                React.createElement(ContentContainer, null,
                    React.createElement(Typography, { textToken: 'text-style/headings/titles/bold/grande' }, title),
                    React.createElement(SpacerVertical, { spaceToken: 'space/200' }),
                    React.createElement(Typography, { textToken: 'text-style/text/paragraphs/default/regular' }, description)),
                React.createElement(ActionButtonContainer, null,
                    React.createElement(ButtonPrimary, { size: 'medium', icon: ActionIconNewTab, iconAlignment: 'right', onClick: onClick, onPress: onClick }, buttonText))))));
}

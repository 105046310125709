import { ButtonPrimary, SpacerHorizontal, SpacerVertical, Typography } from '@klarna/bubble-ui';
import { usePageContext } from '@merchant-portal/framework';
import { completeAuthentication } from 'mage-common';
import { hooks as coreHooks } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import SetUpRequired from './SetUpRequired';
const checkIfSetUpIsRequired = (authenticationStatus) => {
    const isSetUpRequired = authenticationStatus.password === 'NOT_SET_UP' || authenticationStatus.mfa === 'NOT_SET_UP';
    return isSetUpRequired;
};
const ContentContainer = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const ButtonContainer = styled.div `
  display: flex;
  justify-content: space-between;
`;
const Button = styled(ButtonPrimary) `
  ${({ isModal }) => isModal && `
    width: 100%;
  `}
`;
const Content = ({ isModal = false, onClose = () => { } }) => {
    const { setPageContext = () => { } } = usePageContext();
    const tokenHelper = coreHooks.useTokenHelper();
    const t = i18nHooks.useTranslator();
    const authenticationStatus = tokenHelper === null || tokenHelper === void 0 ? void 0 : tokenHelper.getAuthenticationStatus();
    const isSetUpRequired = authenticationStatus ? checkIfSetUpIsRequired(authenticationStatus) : true;
    useEffect(() => {
        !isModal && setPageContext({
            title: isSetUpRequired ? t('core.stepUpAuthentication.setUp.title') : t('core.stepUpAuthentication.login.title')
        });
    }, [isModal]);
    return (React.createElement(ContentContainer, null,
        isModal &&
            React.createElement(React.Fragment, null,
                React.createElement(Typography, { textToken: 'text-style/headings/titles/bold/primary' }, isSetUpRequired ? t('core.stepUpAuthentication.setUp.title') : t('core.stepUpAuthentication.login.title')),
                React.createElement(SpacerVertical, { spaceToken: "space/400" })),
        isSetUpRequired &&
            React.createElement(React.Fragment, null,
                React.createElement(SetUpRequired, null),
                React.createElement(SpacerVertical, { spaceToken: "space/400" })),
        React.createElement(ButtonContainer, null,
            isModal &&
                React.createElement(React.Fragment, null,
                    React.createElement(Button, { isModal: isModal, onClick: onClose, size: 'medium' }, t('core.stepUpAuthentication.skipButton')),
                    React.createElement(SpacerHorizontal, { spaceToken: "space/100" })),
            React.createElement(Button, { isModal: isModal, onClick: () => completeAuthentication(), size: 'medium' }, isSetUpRequired ? t('core.stepUpAuthentication.setUp.continueButton') : t('core.stepUpAuthentication.login.continueButton')))));
};
export default Content;

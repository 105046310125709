import useIsMfaLocked from '../../hooks/useIsMfaLocked';
import { useStepUpAuthenticationModal } from '../StepUpAuthentication';
const useMfaLockedButton = ({ onLockedClick, onUnlockedClick }) => {
    const isMfaLocked = useIsMfaLocked();
    const { Modal: MfaModal, openModal } = useStepUpAuthenticationModal();
    const handleClick = () => {
        if (isMfaLocked) {
            onLockedClick();
            openModal();
            return;
        }
        onUnlockedClick();
    };
    return {
        handleClick,
        isMfaLocked,
        MfaModal
    };
};
export default useMfaLockedButton;

import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    tasks: [],
    isError: false,
    isFetching: false
};
export default createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        init: state => {
            state.isFetching = true;
            state.isError = false;
        },
        success: (state, action) => {
            state.isFetching = false;
            state.isError = false;
            state.tasks = action.payload;
        },
        failure: state => {
            state.isFetching = false;
            state.isError = true;
        }
    }
});

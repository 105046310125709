import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SpacerVertical, Typography } from '@klarna/bubble-ui'
import { Message } from 'mage-i18n'
import FeedbackSelector from './FeedbackSelector'
import FeedbackAlert from './FeedbackAlert'

Feedback.propTypes = {
  title: PropTypes.string.isRequired,
  yesOptionLabel: PropTypes.string.isRequired,
  noOptionLabel: PropTypes.string.isRequired,
  yesFeedbackAlert: PropTypes.string,
  noFeedbackAlert: PropTypes.string,
  onSelect: PropTypes.func,
  category: PropTypes.string.isRequired,
  referenceType: PropTypes.string.isRequired,
  referenceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  noFeedbackComponent: PropTypes.node
}

export default function Feedback ({
  title,
  yesOptionLabel,
  noOptionLabel,
  yesFeedbackAlert,
  noFeedbackAlert,
  noFeedbackComponent = null,
  onSelect = () => {},
  category,
  referenceType,
  referenceId
}) {
  const [response, setResponse] = useState(null)

  useEffect(() => {
    setResponse(null)
  }, [referenceId])

  return (
    <div>
      <Typography textToken='text-style/headings/titles/bold/grande' id='feedback-title'>
        <Message path={title} />
      </Typography>

      <FeedbackSelector
        value={response}
        onSelect={handleSelect}
        yesOptionLabel={yesOptionLabel}
        noOptionLabel={noOptionLabel}
      />

      {response === 'yes' && <FeedbackAlert message={yesFeedbackAlert} />}
      {response === 'no' && <FeedbackAlert message={noFeedbackAlert} />}

      <SpacerVertical spaceToken='space/200' />

      {response === 'no' && noFeedbackComponent}
    </div>
  )

  function handleSelect (value) {
    setResponse(value)

    const isPositive = response === 'no'

    onSelect(isPositive)
  }
}

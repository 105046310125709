import React from 'react'
import PropTypes from 'prop-types'
import { AlertBlock, SpacerVertical, IconInformation } from '@klarna/bubble-ui'

TermList.propTypes = {
  termIds: PropTypes.arrayOf(PropTypes.string)
}

export default function TermList ({ terms = [] }) {
  return terms.map(term => (
    <React.Fragment key={term.id}>
      <AlertBlock title={term.word} content={[term.definition]} iconType={IconInformation} />
      <SpacerVertical spaceToken='space/200' />
    </React.Fragment>
  ))
}

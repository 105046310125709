import { MultiSelector, Selector } from '@klarna/mp-ui';
import { selectors as coreSelectors } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import * as R from 'ramda';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { mapEntitiesToOptions } from './utils';
const isMultiSelector = (props) => {
    return props.isMultiselect;
};
const formatEntityName = (entity) => `${entity.type}.${entity.id}`;
const EntitySelector = (props) => {
    const { borderless, children, closeText, disabled, entities = [], fullWidthTrigger, id, label, loading, maxHeight, maxWidth, onClose = () => undefined } = props;
    const t = i18nHooks.useTranslator();
    const merchantsData = useSelector(coreSelectors.getMerchants);
    const partnerAccountsData = useSelector(coreSelectors.getPartnerAccounts);
    const options = mapEntitiesToOptions(entities, {
        merchants: merchantsData,
        partnerAccounts: partnerAccountsData
    });
    let entitiesType;
    if (entities.every(R.propEq('klarna_resource', 'type'))) {
        entitiesType = 'klarna_resource';
    }
    else if (entities.every(R.propEq('merchant', 'type'))) {
        entitiesType = 'merchant';
    }
    else {
        entitiesType = 'default';
    }
    if (isMultiSelector(props)) {
        const [values, setValues] = useState((props.initialValues || []).map(formatEntityName));
        return (React.createElement(MultiSelector, { borderless: borderless, closeLabel: closeText || t('core.entitySelector.closeText'), closeWithSelectionLabel: props.closeTextWithSelection || t('core.entitySelector.closeTextWithSelection'), deselectAllOptionLabel: props.deselectAllOptionText || t('core.entitySelector.deselectAllOptionText'), disabled: disabled, emptyStateLabel: props.emptyStateText || t(`core.entitySelector.emptyStateText.${entitiesType}`), isFullWidthTrigger: fullWidthTrigger, id: id, label: label || t(`core.entitySelector.label.${entitiesType}`), loading: loading, maxHeight: maxHeight, maxWidth: maxWidth, onChange: selectedValues => {
                setValues(selectedValues);
                const selectedEntities = entities.filter(entity => {
                    return selectedValues.includes(formatEntityName(entity));
                });
                if (props.onChange !== undefined) {
                    props.onChange(selectedEntities);
                }
            }, onClose: onClose, options: options, searchLabel: props.searchFieldLabel || t(`core.entitySelector.searchFieldLabel.${entitiesType}`), selectAllOptionLabel: props.selectAllOptionText || t(`core.entitySelector.selectAllOptionText.${entitiesType}`), selectedAllLabel: props.selectedAllText || t(`core.entitySelector.selectedAllText.${entitiesType}`), selectedLabel: props.selectedText || t(`core.entitySelector.selectedText.${entitiesType}`), values: values, withSearch: entities.length > 4, withSelectAll: entities.length > 1 }, children));
    }
    else {
        const [value, setValue] = useState(props.initialValue ? formatEntityName(props.initialValue) : undefined);
        if (value !== undefined && !options.find(R.propEq(value, 'value'))) {
            setValue(undefined);
        }
        return (React.createElement(Selector, { borderless: borderless, closeLabel: closeText || t('core.entitySelector.closeText'), disabled: disabled, isFullWidthTrigger: fullWidthTrigger, id: id, label: label || t(`core.entitySelector.label.${entitiesType}`), loading: loading, maxHeight: maxHeight, maxWidth: maxWidth, onChange: selectedValue => {
                setValue(selectedValue);
                const selectedEntity = entities.find(entity => {
                    return selectedValue === formatEntityName(entity);
                });
                if (props.onChange !== undefined) {
                    props.onChange(selectedEntity);
                }
            }, onClose: onClose, options: options, value: value }, children));
    }
};
export default EntitySelector;

import React from 'react'
import PropTypes from 'prop-types'
import { Typography, SpacerVertical } from '@klarna/bubble-ui'
import { useCMSEntry } from 'mage-cms'
import { Analytics } from 'mage-insights'
import { useFilterArticlesByExperiment } from '../../hooks'
import ArticleList from '../Article/ArticleList'
import FallbackLoader from '../FallbackLoader'

TopicOverviewPage.propTypes = {
  parameters: PropTypes.shape({
    topicSlug: PropTypes.string
  }),
  navigation: PropTypes.shape({
    push: PropTypes.func,
    replace: PropTypes.func,
    pop: PropTypes.func
  }).isRequired
}

export default function TopicOverviewPage ({ parameters: { topicSlug }, navigation }) {
  const topic = useCMSEntry({ filters: { slug: topicSlug }, contentType: 'UHQMTopic' })

  if (!topic) return <FallbackLoader />

  return (
    <div>
      <Analytics
        category='uhqm/page/topic'
        action='pageview'
        label={topic.name}
        referenceType='topic'
        referenceId={topic.id}
      />
      <Typography textToken='text-style/headings/titles/bold/primary' accessibilityPreset='heading 1' id='uhqm-topic-title'>
        <span style={{ overflowWrap: 'break-word' }}>{topic.name}</span>
      </Typography>

      <SpacerVertical spaceToken='space/200' />

      <Articles articles={topic.articles} />
    </div>
  )
}

Articles.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
}

function Articles ({ articles }) {
  const activeArticles = useFilterArticlesByExperiment(articles)

  if (!activeArticles) return null

  const visibleArticles = filterHiddenArticles(activeArticles)

  return <ArticleList articles={visibleArticles} analyticsCategory='uhqm/article-title/topic' />
}

function filterHiddenArticles (articles) {
  return articles.filter(article => !article.hidden)
}

import { Layout } from '@klarna/mp-ui'
import InsightsThisWeek from '@merchant-portal/insights-widget'
import React from 'react'

import Tasklist from './Tasklist'

function Widgets () {
  return (
    <Layout.Section verticalSpacing='space/200'>
      <Layout.Column mobileWidth={12} tabletWidth={6} desktopWidth={4}>
        <Tasklist />
      </Layout.Column>
      <Layout.Column mobileWidth={12} tabletWidth={6} desktopWidth={8}>
        <InsightsThisWeek />
      </Layout.Column>
    </Layout.Section>
  )
}

export default Widgets

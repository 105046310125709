
export const CHAT_SESSION_STORAGE_KEY = 'ACTIVE_CHAT_SESSION_DATA'
export const CHAT_SECURE_SESSION_STORAGE_KEY = 'ACTIVE_SECURE_CHAT_SESSION_DATA'
// temporary solution until Merchant Portal has proper language selection
// this only works as long as we only support countries with one language
export const LOCALES = {
  SE: 'sv_SE',
  DE: 'de_DE',
  NO: 'nb_NO',
  GB: 'en_GB',
  FI: 'fi_FI',
  US: 'en_US',
  NL: 'nl_NL',
  AT: 'de_AT',
  DK: 'da_DK',
  AU: 'en_AU',
  BE: 'nl_BE',
  ES: 'es_ES',
  IT: 'it_IT',
  PT: 'pt_PT',
  MX: 'es_MX',
  CA: 'en_CA',
  CH: 'de_CH'
}

export const regions = ['eu', 'ap', 'us']

export const marketsByRegion = {
  eu: ['SE', 'NO', 'DE', 'GB', 'FI', 'NL', 'AT', 'DK', 'BE', 'ES', 'IT', 'SM', 'IE', 'PT', 'CH'],
  ap: ['AU', 'NZ'],
  us: ['US', 'MX', 'CA']
}

import { MenuTab, SpacerVertical } from '@klarna/bubble-ui';
import { hooks as i18nHooks } from 'mage-i18n';
import Insights from 'mage-insights';
import React, { useState } from 'react';
import PluginTabContent from './PluginTabContent';
import WebSdkTabContent from './WebSdkTabContent';
const ANALYTICS_CATE_PREFIX = 'home-fe/v3/boost-home/integration-type-tabs/';
const TABS = [
    {
        id: 'plugin',
        labelKey: 'home-fe.boosters-home-v03.tabs.plugin',
        component: React.createElement(PluginTabContent, null)
    },
    {
        id: 'websdk',
        labelKey: 'home-fe.boosters-home-v03.tabs.websdk',
        component: React.createElement(WebSdkTabContent, null)
    }
];
const getOptions = (t) => (TABS.map(({ id, labelKey }) => ({
    value: id,
    label: t(labelKey)
})));
export default function GuideTabs() {
    var _a;
    const [activeTab, setActiveTab] = useState('plugin');
    const t = i18nHooks.useTranslator();
    const onTabChange = (activeTab) => {
        setActiveTab(activeTab);
        Insights.event({
            category: ANALYTICS_CATE_PREFIX + activeTab,
            action: 'click'
        });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(MenuTab, { value: String(activeTab), onChange: activeTab => onTabChange(activeTab), options: getOptions(t) }),
        React.createElement(SpacerVertical, { spaceToken: 'space/400' }), (_a = TABS.find(({ id }) => id === activeTab)) === null || _a === void 0 ? void 0 :
        _a.component));
}

import React from 'react'
import PropTypes from 'prop-types'
import {
  AlertBannerSuccess,
  IconHeart,
  SpacerVertical
} from '@klarna/bubble-ui'
import { hooks as i18nHooks } from 'mage-i18n'

FeedbackAlert.propTypes = {
  message: PropTypes.string
}

export default function FeedbackAlert ({ message }) {
  const t = i18nHooks.useTranslator()

  if (!message) {
    return null
  }

  return (
    <>
      <SpacerVertical spaceToken='space/200' />
      <AlertBannerSuccess
        data-browser-test-id='feedback-alert'
        iconType={IconHeart}
        content={t(message)}
      />
    </>
  )
}

import React from 'react'
import { useCMSEntries } from 'mage-cms'
import { useHelpContext, useFilterArticlesByExperiment } from '../../hooks'
import ResultsView from './ResultsView'
import LoadingView from './LoadingView'
import NoResultsMessage from './NoResultsMessage'
import { SpacerVertical } from '@klarna/bubble-ui'

export default function SearchResults () {
  const { searchQuery: query } = useHelpContext()

  const topics = useCMSEntries({ contentType: 'UHQMTopic', filters: { query } })
  const articles = useCMSEntries({
    contentType: 'UHQMArticle',
    filters: { query, 'hidden[ne]': true }
  })
  const filteredArticles = useFilterArticlesByExperiment(articles)

  if (topics && filteredArticles) {
    const hasResults = !!topics.length || !!filteredArticles.length

    return (
      <>
        {hasResults && (
          <>
            <ResultsView topics={topics} articles={filteredArticles} />
            <SpacerVertical spaceToken='space/200' />
          </>
        )}
        {!hasResults && <NoResultsMessage />}
      </>
    )
  } else {
    return <LoadingView />
  }
}

import * as R from 'ramda';
const partnerAccountToOption = (partnerAccount, partnerAccountsData) => {
    const partnerAccountId = partnerAccount.id;
    const partnerAccountShortId = partnerAccountId.split(':').pop();
    const partnerAccountData = partnerAccountsData.find(({ id }) => id === partnerAccountId);
    return {
        description: partnerAccountShortId,
        disabled: false,
        value: `${partnerAccount.type}.${partnerAccountId}`,
        label: partnerAccountData ? partnerAccountData.name : partnerAccountShortId
    };
};
const merchantToOption = (merchant, merchantsData) => {
    const merchantData = R.find(R.propEq(merchant.id, 'merchantId'), merchantsData);
    const storeName = merchantData ? R.prop('storeName', merchantData) : undefined;
    const baseOption = {
        disabled: false,
        value: `${merchant.type}.${merchant.id}`
    };
    if (storeName) {
        return Object.assign(Object.assign({}, baseOption), { description: merchant.id, label: storeName });
    }
    else {
        return Object.assign(Object.assign({}, baseOption), { label: merchant.id });
    }
};
const mapEntitiesToOptions = (entities, data) => {
    return entities.map(entity => {
        switch (entity.type) {
            case 'klarna_resource':
                return partnerAccountToOption(entity, data.partnerAccounts);
            case 'merchant':
                return merchantToOption(entity, data.merchants);
            default:
                return {
                    description: entity.id,
                    disabled: false,
                    value: `${entity.type}.${entity.id}`,
                    label: entity.id
                };
        }
    })
        .sort((optionOne, optionTwo) => optionOne.label.localeCompare(optionTwo.label));
};
export { mapEntitiesToOptions };

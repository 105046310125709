import { SpacerVertical, Typography } from '@klarna/bubble-ui';
import { withStyleSheetOverride } from '@klarna/higher-order-components';
import { Message } from 'mage-i18n';
import PropTypes from 'prop-types';
import React from 'react';
import LockEnhanced from './Lock';
const getDefaultStyleSheet = () => ({
    iconContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: '80px 0 40px'
    }
});
const AccessDenied = ({ styleSheet }) => {
    return (React.createElement("div", { id: 'access-denied' },
        React.createElement("div", { style: styleSheet.iconContainer },
            React.createElement(LockEnhanced, null)),
        React.createElement(Typography, { textToken: "text-style/headings/titles/bold/primary", textAlignToken: "text-alignment/center", accessibilityPreset: 'heading 1' },
            React.createElement(Message, { path: 'core.accessDenied.title' })),
        React.createElement(SpacerVertical, { spaceToken: "space/200" }),
        React.createElement(Typography, { textToken: 'text-style/text/paragraphs/body/regular', textAlignToken: 'text-alignment/center', accessibilityPreset: 'paragraph' },
            React.createElement(Message, { path: 'core.accessDenied.message' }))));
};
AccessDenied.propTypes = {
    styleSheet: PropTypes.shape({
        iconContainer: PropTypes.object
    })
};
export default withStyleSheetOverride(getDefaultStyleSheet)(AccessDenied);
export { AccessDenied };

import { Modal as MpUiModal } from '@klarna/mp-ui';
import React from 'react';
import Content from './Content';
const Modal = ({ isOpen, onClose = () => { } }) => {
    if (!isOpen)
        return null;
    return (React.createElement(MpUiModal, { onClose: onClose, useWindowHeight: true },
        React.createElement(Content, { isModal: true, onClose: onClose })));
};
export default Modal;

import React, { useState } from 'react'
import {
  ButtonRoundPrimary,
  IconShare,
  Radio,
  SpacerHorizontal,
  Typography,
  Frame
} from '@klarna/bubble-ui'
import { Modal } from '@klarna/mp-ui'
import { useHelpContext } from '../../hooks'
import { hooks as i18nHooks } from 'mage-i18n'

function getDeepLink (path, parameters) {
  switch (path) {
    case 'article':
      return `${path}:${parameters.articleSlug}`
    case 'topic':
      return `${path}:${parameters.topicSlug}`
    case 'tour':
      return `${path}:${parameters.tourSlug}`
  }
}

function ShareButton () {
  const [showShareModal, setShowShareModal] = useState(false)
  const t = i18nHooks.useTranslator()

  function toggleModal () {
    setShowShareModal(!showShareModal)
  }
  const {
    navigation: {
      activePage: { path, parameters }
    }
  } = useHelpContext()
  const deeplink = getDeepLink(path, parameters)
  const searchParams = new URLSearchParams(location.search)
  searchParams.append('help-page', deeplink)

  return (
    <>
      <SpacerHorizontal spaceToken='space/200' />
      {/* eslint-disable-next-line react/jsx-no-bind */}
      <ButtonRoundPrimary id='uhqm-home-button' size='small' onPress={toggleModal}>
        <IconShare />
      </ButtonRoundPrimary>
      {showShareModal && (
        // eslint-disable-next-line react/jsx-no-bind
        <Modal onClose={toggleModal} useWindowHeight={false}>
          <Typography textToken='text-style/headings/titles/bold/grande'>{t(`help-tool.shareButton.title.${path}`)}</Typography>
          <Radio
            name='radio'
            defaultValue='home'
            options={[
              {
                description: t('help-tool.shareButton.descriptionHome'),
                value: 'home',
                label: t('help-tool.shareButton.radioItemHome'),
                content: (
                  <Frame backgroundColorToken='colors/backgrounds/subtle-inline'>
                    <Typography textToken='text-style/text/paragraphs/body/bold'>
                      {`https://portal.klarna.com?${searchParams.toString()}`}
                    </Typography>
                  </Frame>
                )
              },
              {
                description: t('help-tool.shareButton.descriptionCurrent'),
                value: 'current',
                label: t('help-tool.shareButton.radioItemCurrent'),
                content: (
                  <Frame backgroundColorToken='colors/backgrounds/subtle-inline'>
                    <Typography textToken='text-style/text/paragraphs/body/bold'>
                      {`https://portal.klarna.com${location.pathname}?${searchParams.toString()}`}
                    </Typography>
                  </Frame>
                )
              }
            ]}
          />
        </Modal>
      )}
    </>
  )
}

export default ShareButton

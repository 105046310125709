import React from 'react'
import { path } from 'ramda'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { useCMSEntry, useCMSEntries } from 'mage-cms'
import { Analytics } from 'mage-insights'
import {
  Link,
  SpacerVertical,
  Typography
} from '@klarna/bubble-ui'
import HelpPageRelatedContent from '../HelpPageRelatedContent'
import { useHelpContext } from '../../hooks'
import TopicList from '../Topic/TopicList'
import FallbackLoader from '../FallbackLoader'
import ArticleFeedback from './ArticleFeedback'

function isExternalLink (url) {
  return !(url.startsWith(window.location.origin) || url.startsWith('/'))
}

export default function ArticlePage () {
  const {
    navigation,
    navigation: {
      activePage: {
        parameters: { articleSlug: slug }
      }
    }
  } = useHelpContext()
  const article = useCMSEntry({ contentType: 'UHQMArticle', filters: { slug } })
  const topics = useCMSEntries({ contentType: 'UHQMTopic', filters: { links_to_entry: article?.id }, skip: !article })

  if (!article) return <FallbackLoader />

  return (
    <div>
      <Analytics
        category='uhqm/page/article'
        action='pageview'
        label={article.title}
        referenceType='article'
        referenceId={article.id}
      />
      <div>
        <Typography textToken='text-style/headings/titles/bold/secondary' accessibilityPreset='heading 2' id='uhqm-article-title'>{article.title}</Typography>
        <div>{documentToReactComponents(article.body, renderOptions(navigation))}</div>
      </div>
      <SpacerVertical spaceToken='space/200' />
      {topics && <TopicList topics={topics} analyticsCategory='uhqm/topic-title/article' />}
      <SpacerVertical large />
      <HelpPageRelatedContent
        article={article}
        analyticsCategory='uhqm/article-title/related/article'
      />

      <SpacerVertical large />
      <ArticleFeedback articleId={article.id} articleTitle={article.title} />
    </div>
  )
}

const getImageUrl = path(['data', 'target', 'file', 'url'])

export function renderOptions (navigation) {
  return {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.PARAGRAPH]: (node, children) => <Typography textToken='text-style/text/paragraphs/body/bold'>{children}</Typography>,
      // eslint-disable-next-line react/display-name
      [INLINES.ENTRY_HYPERLINK]: (node, children) => (
        <Analytics action='click'>
          {track => (
            <Link
              textToken='text-style/text/paragraphs/body/medium'
              isUnderlined
              onPress={() => {
                const { contentType, slug, id } = node.data.target
                switch (contentType) {
                  case 'UHQMTour':
                    track({
                      category: 'uhqm/article-content-link/tour',
                      label: slug,
                      referenceType: 'tour',
                      referenceId: id
                    })
                    navigation.push('tour', { tourSlug: slug })
                    break
                  case 'UHQMTopic':
                    track({
                      category: 'uhqm/article-content-link/topic',
                      label: slug,
                      referenceType: 'topic',
                      referenceId: id
                    })
                    navigation.push('topic', { topicSlug: slug })
                    break
                  case 'UHQMArticle':
                    track({
                      category: 'uhqm/article-content-link/article',
                      label: slug,
                      referenceType: 'article',
                      referenceId: id
                    })
                    navigation.push('article', { articleSlug: slug })
                    break
                }
              }}
            >
              {children}
            </Link>
          )}
        </Analytics>
      ),
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: (node, children) => {
        if (node.data.uri === 'help-page:change-of-bad') {
          return (
            <Link
              textToken='text-style/text/paragraphs/body/medium'
              isUnderlined
              onPress={() => {
                navigation.push('bad')
              }}
            >
              {children}
            </Link>
          )
        }

        return (
          <Link
            textToken='text-style/text/paragraphs/body/medium'
            isUnderlined
            target={isExternalLink(node.data.uri) ? '_blank' : '_self'}
            href={node.data.uri}
          >
            {children}
          </Link>
        )
      },
      // eslint-disable-next-line react/display-name
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return (
          <Link
            textToken='text-style/text/paragraphs/body/medium'
            isUnderlined
            target='_blank' href={node.data.target.file.url}
          >
            {children}
          </Link>
        )
      },
      // eslint-disable-next-line react/display-name
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
        <img
          style={{ maxWidth: '100%', marginTop: 5 }}
          src={getImageUrl(node)}
          {...(node.data.target.description ? { alt: node.data.target.description } : {})}
        />
      )
    }
  }
}

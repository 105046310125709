import { IconAppLauncher, IconAssets, IconAssistant, IconDebt, IconDelivery, IconEdit, IconGroceries, IconGuidebook, IconKlarnaK, IconOrder, IconOther, IconProfile, IconProfileAlternative, IconSettings, IconSpeechBubble, IconStores, IconSustainability, IconTransfers, Typography } from '@klarna/bubble-ui';
import { constants as commonConstants } from 'mage-common';
import PropTypes from 'prop-types';
import React from 'react';
const { CLIENT_IDS } = commonConstants;
function renderIcon(clientId, color) {
    switch (clientId) {
        case CLIENT_IDS.NKO_APP:
            return React.createElement(IconKlarnaK, { color: color });
        case CLIENT_IDS.ORDERS:
        case CLIENT_IDS.NKO_ORDERS:
            return React.createElement(IconOrder, { color: color });
        case CLIENT_IDS.SETTLEMENTS:
        case CLIENT_IDS.NKO_SETTLEMENTS:
            return React.createElement(IconAssets, { color: color });
        case CLIENT_IDS.LOGS:
            return React.createElement(IconTransfers, { color: color });
        case CLIENT_IDS.USERS:
            return React.createElement(IconProfile, { color: color });
        case CLIENT_IDS.SETTINGS:
        case CLIENT_IDS.NKO_SETTINGS:
            return React.createElement(IconSettings, { color: color });
        case CLIENT_IDS.SELLER:
            return React.createElement(IconStores, { color: color });
        case CLIENT_IDS.UPSTREAM:
            return React.createElement(IconSpeechBubble, { color: color });
        case CLIENT_IDS.HOME:
            return React.createElement(IconAppLauncher, { color: color });
        case CLIENT_IDS.LENDING:
            return React.createElement(IconSustainability, { color: color });
        case CLIENT_IDS.BRANDING:
            return React.createElement(IconOther, { color: color });
        case CLIENT_IDS.PRODUCTS:
            return React.createElement(IconKlarnaK, { color: color });
        case CLIENT_IDS.INTEGRATION_GUIDES:
            return React.createElement(IconGuidebook, { color: color });
        case CLIENT_IDS.DISPUTES:
            return React.createElement(IconDebt, { color: color });
        case CLIENT_IDS.LOGISTICS:
            return React.createElement(IconDelivery, { color: color });
        case CLIENT_IDS.SUPPORT:
            return React.createElement(IconAssistant, { color: color });
        case CLIENT_IDS.KEC:
            return React.createElement(IconGroceries, { color: color });
        case CLIENT_IDS.SIWK:
            return React.createElement(IconProfileAlternative, { color: color });
        case CLIENT_IDS.SELF_SERVICE:
            return React.createElement(IconEdit, { color: color });
        default:
            return clientId ? (React.createElement("div", { style: { position: 'relative', top: -3, opacity: 0.7 } },
                React.createElement(Typography, { textToken: "text-style/headings/titles/bold/primary", textAlignToken: "text-alignment/center" }, clientId.substr(0, 1).toLocaleLowerCase()))) : null;
    }
}
export function AppIcon({ clientId, color }) {
    return React.createElement("div", null, renderIcon(clientId, color));
}
AppIcon.defaultProps = {
    color: 'black'
};
AppIcon.propTypes = {
    clientId: PropTypes.oneOf(Object.values(CLIENT_IDS)).isRequired,
    color: PropTypes.string
};
export default AppIcon;

import React from 'react'
import PropTypes from 'prop-types'
import { Message } from 'mage-i18n'
import { Typography, Link, SpacerVertical } from '@klarna/bubble-ui'
import { useHelpContext, useFilterArticlesByExperiment } from '../hooks'

RelatedContent.propTypes = {
  title: PropTypes.string.isRequired,
  articles: PropTypes.arrayOf(PropTypes.object.isRequired),
  analyticsCategory: PropTypes.string.isRequired
}

const getNavigationHandler = (navigation, article) => event => {
  event.preventDefault()
  navigation.push('article', { articleSlug: article.slug })
}

export default function RelatedContent ({ articles, title, analyticsCategory }) {
  const { navigation } = useHelpContext()

  const activeArticles = useFilterArticlesByExperiment(articles)

  if (!activeArticles) return null

  const visibleArticles = activeArticles.filter(article => !article.hidden)

  if (!visibleArticles || !visibleArticles.length) return null

  const slicedArticles = visibleArticles.slice(0, 4)

  return (
    <div>
      <SpacerVertical spaceToken='space/200' />

      <Typography textToken='text-style/headings/titles/bold/grande'>
        <Message path={title} />
      </Typography>

      {slicedArticles.map(article => (
        <div key={article.id}>
          <SpacerVertical spaceToken='space/100' />

          <Link textToken='text-style/text/paragraphs/body/medium' isUnderlined onPress={getNavigationHandler(navigation, article)}>
            {article.title}
          </Link>
        </div>
      ))}

      <SpacerVertical spaceToken='space/200' />
    </div>
  )
}

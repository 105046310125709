import React from 'react'
import { Message } from 'mage-i18n'
import { Typography } from '@klarna/bubble-ui'

export default function NoResultsMessage () {
  return (
    <>
      <Typography textToken='text-style/text/labels/body/medium' id='uhqm-search-no-results'>
        <Message
          path='help-tool.search.results.empty'
          fallback='Sorry, no results were found for your query. Try searching for a different term.'
        />
      </Typography>
    </>
  )
}

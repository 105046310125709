import React from 'react'
import { Message } from 'mage-i18n'
import { Typography } from '@klarna/bubble-ui'

export default function LoadingView () {
  return (
    <Typography textToken='text-style/text/labels/body/medium' id='uhqm-search-loading'>
      <Message path='help-tool.search.loading' fallback='Loading ...' />
    </Typography>
  )
}

import { useGetToken } from '@klarna/mp-ui'
import { hooks as i18nHooks } from 'mage-i18n'
import PropTypes from 'prop-types'
import React from 'react'
import styled, { css } from 'styled-components'

import AppTile from './AppTile'

const AppsWrapper = styled.div(() => {
  const gap = useGetToken('space/200').value()

  return css({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap,
    width: '100%'
  })
})

function openNkoApp (url) {
  window.open(url, '_blank')
}

function NkoApps ({ apps = [] }) {
  const t = i18nHooks.useTranslator()

  return (
    <AppsWrapper id='nkoApps'>
      {apps.map(({ clientId, url }) => (
        <AppTile
          key={clientId}
          clientId={clientId}
          onClick={() => openNkoApp(url)}
          title={t(`core.apps.${clientId}.title`)}
        />
      ))}
    </AppsWrapper>
  )
}

NkoApps.propTypes = {
  apps: PropTypes.arrayOf(PropTypes.shape({
    clientId: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }))
}

export default NkoApps

import { __awaiter } from "tslib";
import * as Sentry from '@sentry/react';
function createTask(countData, appId, type, url) {
    const count = parseInt(countData);
    if (!count)
        return;
    return {
        appType: appId + ':' + type,
        appId,
        type,
        url,
        count
    };
}
const createFetchers = clients => {
    function userTasks(userId, userRegion) {
        return __awaiter(this, void 0, void 0, function* () {
            const { data } = yield clients.notifier.get(`/v1/users/${userId}/tasks?category=todo`, { region: userRegion });
            return data;
        });
    }
    function unfulfilledOrders() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const resp = yield clients.orders.get('/count/unfulfilled');
                return createTask(resp.data, 'orders-fe', 'tocapture', '/orders/to-capture#realm=merchants');
            }
            catch (err) {
                // Catch error so that if this fails the task list
                // will still display the normal tasks
                Sentry.captureException(err);
            }
        });
    }
    function expiringSoonOrders() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const resp = yield clients.orders.get('/count/expiring-soon');
                return createTask(resp.data, 'orders-fe', 'expiring', '/orders/expires-soon#realm=merchants');
            }
            catch (err) {
                Sentry.captureException(err);
            }
        });
    }
    return {
        userTasks,
        unfulfilledOrders,
        expiringSoonOrders
    };
};
export default function createRequests(clients, userId, userRegion, { includeOrders = false } = {}) {
    const fetch = createFetchers(clients);
    const requests = [fetch.userTasks(userId, userRegion)];
    if (includeOrders) {
        requests.push(fetch.expiringSoonOrders(), fetch.unfulfilledOrders());
    }
    return requests;
}

import { SpacerVertical } from '@klarna/bubble-ui';
import { Layout } from '@klarna/mp-ui';
import { TrackImpression } from 'mage-insights';
import React from 'react';
import AccessBanner from '../components/AccessBanner';
import LoaderOverlay from '../components/LoaderOverlay';
import MidSelector from '../components/MidSelector';
import { useShowMidSelector } from '../hooks/useShowMidSelector';
import ErrorBanner from './ErrorBanner';
import GuideTabs from './GuideTabs';
import { PromotionBanner } from './PromotionBanner';
import SetupBoostInfo from './SetupBoostInfo';
const analyticsEvent = {
    category: 'v3/boost-home/boost-dashboard-page',
    action: 'impression'
};
export default function Page() {
    const { mustShowMidSelector } = useShowMidSelector();
    return (React.createElement(TrackImpression, { event: analyticsEvent },
        React.createElement("div", { id: 'boost-installation-guide' },
            React.createElement(Layout.Grid, null,
                mustShowMidSelector && (React.createElement(Layout.Section, null,
                    React.createElement(Layout.Column, { mobileWidth: 12, tabletWidth: 12, desktopWidth: 12 },
                        React.createElement(MidSelector, { maxWidth: '320px' })))),
                React.createElement(Layout.Section, null,
                    React.createElement(Layout.Column, { mobileWidth: 12, tabletWidth: 12, desktopWidth: 12 },
                        React.createElement(PromotionBanner, null))),
                React.createElement(Layout.Section, null,
                    React.createElement(Layout.Column, { mobileWidth: 12, tabletWidth: 12, desktopWidth: 12 },
                        React.createElement(ErrorBanner, null),
                        React.createElement(AccessBanner, null))),
                React.createElement(SpacerVertical, { spaceToken: 'space/600' }),
                React.createElement(Layout.Section, null,
                    React.createElement(Layout.Column, { mobileWidth: 12, tabletWidth: 12, desktopWidth: 12 },
                        React.createElement(SetupBoostInfo, null))),
                React.createElement(SpacerVertical, { spaceToken: 'space/200' }),
                React.createElement(GuideTabs, null)),
            React.createElement(LoaderOverlay, null))));
}

import { SpacerVertical } from '@klarna/bubble-ui';
import { Layout } from '@klarna/mp-ui';
import { usePageContext } from '@merchant-portal/framework';
import { components as clientComponents } from 'mage-client';
import { AccessDenied } from 'mage-components';
import { hooks as coreHooks } from 'mage-core';
import { hooks as i18nHooks } from 'mage-i18n';
import React, { useEffect } from 'react';
import { CAPS } from '../../constants';
import AppCategories from './AppCategories';
import * as banners from './banners';
import NkoWelcomeEnhanced from './NkoWelcome';
import Widgets from './Widgets';
const Home = () => {
    const { setPageContext = () => { } } = usePageContext();
    const t = i18nHooks.useTranslator();
    const tokenHelper = coreHooks.useTokenHelper();
    const isTransactingUser = (tokenHelper !== undefined) ? tokenHelper.isTransactingUser() : false;
    useEffect(() => {
        setPageContext({
            title: t('core.apps.home-fe.title')
        });
    }, [t]);
    return (React.createElement(clientComponents.CapabilitiesProvider, { AccessDeniedComponent: AccessDenied, desired: CAPS },
        React.createElement("div", { id: 'home-page' },
            React.createElement(Layout.Grid, null,
                React.createElement(Layout.Section, null,
                    React.createElement(Layout.Column, null,
                        React.createElement(banners.Important, { title: t('home-fe.important-banner.title'), content: t('home-fe.important-banner.content'), linkLabel: t('home-fe.important-banner.linkLabel'), linkHref: 'https://status.klarna.com/', shouldDisplay: false, regions: [], envs: [] }),
                        React.createElement(NkoWelcomeEnhanced, null),
                        React.createElement(banners.Notification, null))),
                isTransactingUser && (React.createElement(React.Fragment, null,
                    React.createElement(SpacerVertical, { spaceToken: 'space/200' }),
                    React.createElement(Widgets, null))),
                React.createElement(SpacerVertical, { spaceToken: 'space/200' }),
                React.createElement(AppCategories, null),
                React.createElement(SpacerVertical, { spaceToken: 'space/200' })))));
};
export default Home;

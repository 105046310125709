import React from 'react'
import {
  ButtonPrimary,
  SpacerVertical,
  Typography
} from '@klarna/bubble-ui'
import { Message } from 'mage-i18n'
import { useHelpContext } from '../../hooks'

function NotAvailableOnMobile () {
  const { navigation } = useHelpContext()
  return (
    <div>
      <Typography textToken='text-style/headings/titles/bold/grande'>
        <Message path='help-tool.notAvailableMobile.title' fallback='Not Available' />
      </Typography>
      <Typography textToken='text-style/text/paragraphs/body/regular'>
        <Message
          path='help-tool.notAvailableMobile.paragraph'
          fallback='This content is not available on your device.'
        />
      </Typography>
      <SpacerVertical spaceToken='space/200' />
      <ButtonPrimary onClick={() => navigation.pop()}>
        <Message path='help-tool.notAvailableMobile.buttonBack' fallback='Back' />
      </ButtonPrimary>
    </div>
  )
}

export default NotAvailableOnMobile

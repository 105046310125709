import React from 'react'
import {
  ButtonPrimary,
  SpacerVertical,
  AlertBanner,
  IconNotification,
  Typography
} from '@klarna/bubble-ui'
import StoreSelector from './StoreSelector'
import { hooks as i18nHooks } from 'mage-i18n'
import { useField, useFormikContext } from 'formik'
import { FieldTranslationProvider, Field, TextAreaField } from 'formik-fields'
import TopicSelector from './TopicSelector'
import FilesInput from './FilesInput'
import QueryRelatedContent from '../QueryRelatedContent'

export default function Fields () {
  const maxFileSizeMB = 8
  const t = i18nHooks.useTranslator()
  const { isSubmitting } = useFormikContext()
  const [, { value: subject }] = useField('subject')

  return (
    <FieldTranslationProvider namespace='help-tool.support-page.create-ticket.fields' suffix='label'>
      <StoreSelector />
      <SpacerVertical spaceToken='space/200' />
      <Field name='phoneNumber' />
      <SpacerVertical spaceToken='space/400' />
      <Typography textToken='text-style/headings/titles/bold/grande'>{t('help-tool.support-page.create-ticket.fields.topic.question')}</Typography>
      <SpacerVertical spaceToken='space/200' />
      <TopicSelector />
      <SpacerVertical spaceToken='space/200' />
      <Field data-testid='subject' name='subject' />
      <SpacerVertical spaceToken='space/200' />
      <TextAreaField
        data-testid='description'
        name='description'
        placeholder={t('help-tool.support-page.create-ticket.fields.description.label')}
      />
      <SpacerVertical spaceToken='space/200' />
      <QueryRelatedContent
        title='help-tool.support-page.create-ticket.related-content.title'
        analyticsCategory='uhqm/article-title/related/create-support-ticket'
        query={subject}
      />
      <SpacerVertical spaceToken='space/200' />
      <Typography textToken='text-style/headings/titles/bold/grande'>
        {t('help-tool.support-page.create-ticket.fields.attachment.label')}
      </Typography>
      <SpacerVertical spaceToken='space/200' />
      <AlertBanner
        iconType={IconNotification}
        content={[
          `
            ${t('help-tool.support-page.create-ticket.fields.attachment.info')}
            ${t('help-tool.support-page.create-ticket.fields.attachment.supportedFileTypes')}
          `
        ]}
      />
      <SpacerVertical spaceToken='space/200' />
      <FilesInput
        inputId='file-input'
        inputLabel={t('help-tool.support-page.create-ticket.fields.attachment.button')}
        errorContent={[t('help-tool.support-page.create-ticket.fields.attachment.error', { maxSize: maxFileSizeMB })]}
        maxTotalFileSizeMb={maxFileSizeMB}
      />
      <SpacerVertical spaceToken='space/200' />
      <ButtonPrimary type='submit' loading={isSubmitting} data-testid='submit'>
        {t('help-tool.support-page.create-ticket.submit')}
      </ButtonPrimary>
      <SpacerVertical spaceToken='space/200' />
    </FieldTranslationProvider>
  )
}

import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import {
  ButtonSecondary,
  Field,
  AlertBannerError,
  SpacerVertical,
  View
} from '@klarna/bubble-ui'
import FilesPreview from './FilesPreview'

const MB_BYTES = 1024 * 1024

const getMbBytes = (n) => n * MB_BYTES

FilesInput.propTypes = {
  inputIcon: PropTypes.func,
  inputId: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  errorContent: PropTypes.array,
  maxTotalFileSizeMb: PropTypes.number
}

export default function FilesInput ({ inputIcon, inputId, inputLabel, errorContent, maxTotalFileSizeMb }) {
  const maxTotalFileSize = getMbBytes(maxTotalFileSizeMb)
  const uploadLabelRef = useRef(null)
  const [fileInputValue, setFileInputValue] = useState('')
  const [fileError, setFileError] = useState(false)
  const [files, setFiles] = useState([])

  const fileInputOnChange = (value, e) => {
    const totalFilesSize = [...e.target.files].reduce((totalSize, file) => totalSize + file.size, 0)

    if (totalFilesSize >= maxTotalFileSize) {
      setFileInputValue('')
      setFileError(true)
      setFiles([])
    } else {
      setFileInputValue(value)
      setFileError(false)
      setFiles([...e.target.files])
    }
  }

  return (
    <>
      <View style={{ alignItems: 'flex-start' }}>
        <ButtonSecondary
          icon={inputIcon}
          size='small'
          data-testid='upload'
          onClick={e => {
            uploadLabelRef.current.click()
            e.preventDefault()
          }}
        >
          {inputLabel}
        </ButtonSecondary>
      </View>
      <div data-testid='file-input-visibility-wrapper' style={{ display: 'none' }}>
        <label htmlFor={inputId} ref={uploadLabelRef} />
        <Field
          id={inputId}
          name={inputId}
          type='file'
          data-testid='file'
          value={fileInputValue}
          onChange={fileInputOnChange}
          multiple
          accept='.png, .jpg, .pdf, .jpeg'
          isUnderlined
        />
      </div>
      {fileError && (
        <>
          <SpacerVertical spaceToken='space/200' />
          <AlertBannerError data-testid='file-error' content={errorContent} />
          <SpacerVertical spaceToken='space/200' />
        </>
      )}
      <FilesPreview files={files} />
    </>
  )
}

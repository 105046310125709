import React from 'react'
import { SpacerVertical, Typography } from '@klarna/bubble-ui'
import { Message } from 'mage-i18n'
import { useHelpContext } from '../hooks'
import AllTopicsList from './Topic/AllTopicsList'

function HomePage () {
  const { topics } = useHelpContext()

  return (
    <div>
      <Typography textToken='text-style/headings/titles/bold/grande'>
        <Message path='' fallback='What do you need help with?' />
      </Typography>
      <SpacerVertical spaceToken='space/200' />
      <AllTopicsList topics={topics} />
    </div>
  )
}

export default HomePage
